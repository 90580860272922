@font-face {
  font-family: "Neue";   /*Can be any text*/
  src: local("MSBee-Regular.woff2"),
    url("./font/MSBee-Regular.woff2") format("woff2");
}


.main-app {
  text-align: center;
  margin: 0px;
  background:rgb(255, 255, 255)
}

.button-location{
  padding-top: 30px;
}

.cta-button {
  padding: 15px;
  border: none;
  border-radius: 12px;
  min-width: 294px;
  min-height: 113px;
  color: rgb(255, 255, 255);
  font-family: Neue;
  font-weight: Bold;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.cta-button:hover {
  border-radius: 12px;
  min-width: 294px;
  min-height: 113px;
  font-size: 25px;
  background: rgb(195, 53, 66);

}

.connect-wallet-button {
  background: rgb(43, 201, 72,255);
}

.mint-nft-button {
  background: rgb(43, 201, 72,255);
}

.sold-out-nft-button {
  background: rgb(195, 53, 66);
  pointer-events: none;
}

.main-net {
  text-align: center;
  padding-right: calc(50vw - 147px);
  padding-left: calc(50vw - 147px);
  color: rgb(43, 201, 72,255);
  font-family: Neue;
  font-weight: Bold;
  letter-spacing: .01em;
  font-size: 30px;
}
