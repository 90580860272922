 

.counter__output {
    font-size: 40px;
    font-family: Neue;
    font-weight: Bold;
    color: rgb(43, 201, 72,255);
}
 
.btn__container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 50px;
}
 
.control__btn {
    font-size: 20px;
    padding: 10px 20px;
    background-color: transparent;
    color: rgb(255,255,255);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background: rgb(43, 201, 72,255);
}
 
.control__btn:hover {
    background-color: rgb(195, 53, 66);
    color: rgb(255, 255, 255);
}
