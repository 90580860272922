.mintCounter {
    text-align: right;
    color: rgb(43, 201, 72,255);
    font-family: Neue;
    font-weight: Bold;
    width: 294px;
    margin-left: auto;
    margin-right: calc(50vw - 147px);
    font-size: 60px;
    letter-spacing: .01em;

}

.walletConnect {
    text-align: center;
    padding-right: calc(50vw - 147px);
    padding-left: calc(50vw - 147px);
    color: rgb(43, 201, 72,255);
    font-family: Neue;
    font-weight: Bold;
    letter-spacing: .01em;
    font-size: 30px;
}


/* .counter {
    font-size: 42px;
} */

.minted {
    letter-spacing: 0.01em;
    font-size: 18px;
}
